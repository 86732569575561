<template>
	<v-dialog
		v-model="value"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
		dark
	>
		<v-card tile>
		<v-app-bar v-if="isIOS" app color="white" flat dense></v-app-bar>
			<v-toolbar color="transparent" cards flat dense>
				<v-btn icon @click.prevent="closedCamera">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-card-title class="text-h6 font-weight-regular">
					{{ title }}
				</v-card-title>
				<v-spacer></v-spacer
			></v-toolbar>
			<web-cam
				ref="webcam"
				:device-id="deviceId"
				@error="onError"
				@cameras="onCameras"
				@camera-change="onCameraChange"
			/>
		</v-card>

		<!-- <img :src="img" class="img-responsive" /> -->

		<div class="btn-capture pr-3 pl-3">
			<v-btn
				class="white--text"
				color="deep-purple accent-4"
				fab
				@click.prevent="onCapture"
			>
				<v-icon> mdi-camera-plus </v-icon>
			</v-btn>

			<v-btn
				class="white--text ml-2"
				color="deep-purple accent-4"
				fab
				x-small
				@click.prevent="changeCamera"
				:disabled="multipleCameras"
			>
				<v-icon> mdi-camera-flip </v-icon>
			</v-btn>
		</div>
	</v-dialog>
</template>

<script>
import { WebCam } from "vue-web-cam";
import { mapGetters } from "vuex"
export default {
	name: "Camera",
	props: ["value", "title"],
	components: {
		WebCam,
	},
	data() {
		return {
			selectFirstDevice: true,
			img: null,
			camera: null,
			deviceId: null,
			devices: [],
			error: null,
			idCameraSelect: 0,
		};
	},
	computed: {
		...mapGetters(["isIOS"]),
		multipleCameras() {
			return this.devices.length < 2;
		},
	},
	watch: {
		camera: function(id) {
			this.deviceId = id;
		},
		devices: function() {
			// Once we have a list select the first one
			const [first, ...tail] = this.devices;
			console.log(tail);
			if (first) {
				this.camera = first.deviceId;
				this.deviceId = first.deviceId;
			}
		},
	},
	methods: {
		changeCamera () {
			if (this.idCameraSelect) {
				this.idCameraSelect = 0;
			} else {
				this.idCameraSelect = 1;
			}
			// Asginar camara
			this.deviceId = this.devices[this.idCameraSelect].deviceId;
		},
		closedCamera() {
			this.$emit("input", false);
		},
		onCapture() {
			this.img = this.$refs.webcam.capture();
            this.$emit("capture", this.img);
			this.closedCamera();
		},
		onStop() {
			this.$refs.webcam.stop();
		},
		onStart() {
			this.$refs.webcam.start();
		},
		onError(error) {
			this.error = error;
		},
		onCameras(cameras) {
			this.devices = cameras;
		},
		onCameraChange(deviceId) {
			this.deviceId = deviceId;
			this.camera = deviceId;
		},
	},
};
</script>

<style>
.btn-capture {
	position: absolute;
	left: 35%;
	bottom: 45px;
}
</style>
